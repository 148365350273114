import React from 'react'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import { Link as GLink } from 'gatsby'
import { Heading } from 'theme-ui'
import rv from '@components/utils/buildResponsiveVariant'

const CardBodyTitle = ({ variant, title, slug, link }) => {
  const { t } = useTranslation();

  const linkProps = 
    link == "#" || slug == "#" ? 
    {
      as: 'span'
    } :
    ( link
      ? {
          as: 'a',
          href: link,
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      : {
          as: GLink,
          to: slug
        }
    )
  return (
    <Heading {...linkProps} sx={{ variant: rv(variant, 'title') }}>
      <Trans>{title}</Trans>
    </Heading>
  )
}

export default CardBodyTitle
